import striptags from "striptags";
import Link from "next/link";

const CommonCtaSection = ({ data }) => {
	if (!data) return null;
	return (
		<>
			<section className="cta-section blog-cta">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="cta-inner">
								<div className="cta-details">
									<span itemProp="heading" dangerouslySetInnerHTML={{
											__html: data?.value?.title,
										}}></span>
									<p
										itemProp="description"
										dangerouslySetInnerHTML={{
											__html: data?.value?.description,
										}}
									></p>
								</div>
								<div className="cta-request-btn">
									<Link
										href="/request-for-proposal-quote"
										itemProp="link"
										className="card-link cta-card-link"
									>
										<span className="card-link-text">
											{data?.value?.card_link_text}
										</span>
										<span className="arrow-down"></span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default CommonCtaSection;
